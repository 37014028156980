import styled from 'styled-components';

export const FildsetContainer = styled.fieldset`
  display: flex;
  align-items: space-around;
  flex-wrap: wrap;
  margin-top: 12px;
  padding: 10px;
  border: 1px solid #dfe1e6;
  color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const Legend = styled.legend`
  position: absolute;
  font-size: 14px;
  margin-top: -20px;
  background: #fff;
  width: auto;
  margin-left: 10px;
`;
