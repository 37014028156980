/* eslint-disable react/no-array-index-key */
import React, {
  ChangeEvent,
  useEffect,
  useState,
  useContext,
  useCallback,
} from 'react';

import { Radio } from '@atlaskit/radio';

import Button from '@atlaskit/button';

import { FaCalendarAlt, FaFilter } from 'react-icons/fa';
import { InpuTypeDate } from './styles';

import Macro from '~/components/Macro';
import { FilterContext } from '~/context/filter';
import { useFiltro } from '../../FiltroContext';

const options = [
  {
    description: 'Igual à',
    cod_condicao: 0,
  },
  {
    description: 'Maior ou igual à',
    cod_condicao: 1,
  },
  {
    description: 'Menor ou igual à',
    cod_condicao: 2,
  },
  {
    description: 'Diferente de',
    cod_condicao: 3,
  },
  {
    description: 'Entre',
    cod_condicao: 6,
  },
  {
    description: 'Preenchido',
    cod_condicao: 7,
  },
  {
    description: 'Não Preenchido',
    cod_condicao: 8,
  },
];

const TypeDate: React.FC = () => {
  const entre = 6;
  const preenchido = 7;
  const naoPreenchido = 8;

  const { isFilterBlock, handleSetNewValue, filter } = useFiltro();

  const [isMulti, setMulti] = useState<boolean>(false);
  const [isInfo, setInfo] = useState<boolean>(false);
  const [isInfo2, setInfo2] = useState<boolean>(false);
  const { RemoveFilterEmptyByIndex } = useContext(FilterContext);
  const [value, setValue] = useState<string>(
    Array.isArray(filter.des_valor) ? filter.des_valor[0] : filter.des_valor,
  );
  const [valueEnd, setValueEnd] = useState<string>(
    Array.isArray(filter.des_valor) ? filter.des_valor[1] : filter.des_valor,
  );
  const [condition, setCondition] = useState(filter.condicao);
  const [dataInvalida, setDataInvalida] = useState(false);

  useEffect(() => {
    if (condition === entre) {
      setMulti(true);
    } else {
      setMulti(false);
    }
  }, [condition]);

  useEffect(() => {
    if (condition === naoPreenchido || condition === preenchido) {
      return;
    }
    if (Array.isArray(filter.des_valor)) {
      if (filter.des_valor[0].length >= 1 && filter.des_valor[0].length <= 9) {
        setInfo(true);
      }

      if (filter.des_valor[1].length >= 1 && filter.des_valor[1].length <= 9) {
        setInfo2(true);
      }
    } else if (filter.des_valor.length >= 1 && filter.des_valor.length <= 9) {
      setInfo(true);
    }
  }, [filter.des_valor]);

  const handleDesValor = () => {
    type ConditionMappings = {
      [key: number]: undefined | string[] | number[];
    };

    const conditionMappings: ConditionMappings = {
      [preenchido]: undefined,
      [naoPreenchido]: undefined,
      [entre]: [value, valueEnd],
    };

    return conditionMappings[condition] || value;
  };

  const handleSetValueFiltro = () => {
    if (ValidateInputs()) {
      if (!isFilterBlock) RemoveFilterEmptyByIndex(filter.index);
      return;
    }
    const Value = handleDesValor();

    handleSetNewValue({
      index: filter.index,
      des_nome: filter.des_nome,
      nome_bd: filter.nome_bd,
      condicao: condition,
      des_valor: Value,
      cod_tipo_dados: filter.cod_tipo_dados,
      cod_tipo_info: filter.cod_tipo_info,
      cod_campo: filter.cod_campo,
      isBlock: isFilterBlock,
      expressao_validacao: filter.expressao_validacao,
      listagem_info: filter.listagem_info,
      tipo_expressao: filter.tipo_expressao,
    });
  };

  const ValidateInputs = useCallback(() => {
    if (condition === naoPreenchido || condition === preenchido) {
      setDataInvalida(false);
      return false;
    }
    if (
      !(condition === entre
        ? value.length >= 1 && valueEnd.length >= 1
        : value.length >= 1)
    ) {
      setDataInvalida(true);
      return true;
    }
    setDataInvalida(false);
    return false;
  }, [condition, value, valueEnd]);

  const handleStartData = useCallback(
    (newData: string) => {
      setValue(newData);
      if (condition === naoPreenchido || condition === preenchido) {
        setDataInvalida(false);
        return false;
      }
      if (
        !(condition === entre
          ? newData.length >= 1 && valueEnd.length >= 1
          : newData.length >= 1)
      ) {
        setDataInvalida(true);
        return true;
      }
      setDataInvalida(false);
      return false;
    },
    [condition, valueEnd],
  );
  const handleEndData = useCallback(
    (newData: string) => {
      setValueEnd(newData);
      if (condition === naoPreenchido || condition === preenchido) {
        setDataInvalida(false);
        return false;
      }
      if (
        !(condition === entre
          ? value.length >= 1 && newData.length >= 1
          : value.length >= 1)
      ) {
        setDataInvalida(true);
        return true;
      }
      setDataInvalida(false);
      return false;
    },
    [condition, value],
  );

  return (
    <div>
      {options.map((opt: any, i: any) => (
        <div key={`${filter.index} ${i}`}>
          <Radio
            label={opt.description}
            name={`${filter.index} ${opt.description}`}
            testId="radio-default"
            value={opt.cod_condicao.toString()}
            isChecked={condition === opt.cod_condicao}
            onChange={(e) => {
              setCondition(Number(e.target.value));
            }}
          />
        </div>
      ))}
      <div
        style={{
          display:
            condition === naoPreenchido || condition === preenchido
              ? 'none'
              : 'block',
        }}
      >
        <div className="my-2 d-flex align-items-center justify-content-center">
          {isInfo ? (
            <div className="w-75">
              <Macro
                tipoInfo={filter.cod_tipo_info}
                defaultValue={value}
                onChange={(e) => {
                  setValue(e.value);
                }}
              />
            </div>
          ) : (
            <InpuTypeDate
              id={filter.index}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleStartData(e.target.value);
              }}
              value={value}
              type={filter.cod_tipo_dados === 1 ? 'date' : 'datetime-local'}
            />
          )}
          <Button className="mx-2" onClick={() => setInfo(!isInfo)}>
            {isInfo ? <FaCalendarAlt /> : <FaFilter />}
          </Button>
        </div>
        <div className={`my-2 ${isMulti ? 'd-block' : 'd-none'}`}>e</div>
        <div
          className={`my-2 ${
            isMulti ? 'd-flex' : 'd-none'
          } align-items-center justify-content-center`}
        >
          {isInfo2 ? (
            <div className="w-75">
              <Macro
                tipoInfo={filter.cod_tipo_info}
                defaultValue={valueEnd}
                onChange={(e) => {
                  setValueEnd(e.value);
                }}
              />
            </div>
          ) : (
            <InpuTypeDate
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleEndData(e.target.value);
              }}
              value={valueEnd}
              type={filter.cod_tipo_dados === 1 ? 'date' : 'datetime-local'}
              className="my-2"
            />
          )}
          <Button className="mx-2" onClick={() => setInfo2(!isInfo2)}>
            {isInfo2 ? <FaCalendarAlt /> : <FaFilter />}
          </Button>
        </div>
      </div>
      {dataInvalida && (
        <div>
          <small
            style={{
              color: '##ffc107',
            }}
          >
            Data Inválida
          </small>
        </div>
      )}

      <Button
        type="button"
        appearance={dataInvalida ? 'default' : 'primary'}
        isDisabled={dataInvalida}
        onClick={handleSetValueFiltro}
      >
        Atualizar
      </Button>
    </div>
  );
};

export default TypeDate;
