/* eslint-disable no-restricted-globals */
import React from 'react';
import TypeString from './TypeFiltros/TypeString';
import { ContainerValues, SectionSettigs } from './styles';
import TypeInteger from './TypeFiltros/TypeInteger';
import TypeBoolean from './TypeFiltros/TypeBoolean';
import TypeDecimal from './TypeFiltros/TypeDecimal';
import TypeDate from './TypeFiltros/TypeDate';
import HeaderFiltro from './MyComponents/HeaderFiltro';
import { useFiltro } from './FiltroContext';

export const FiltroContent: React.FC = () => {
  const { zindex, isOpenFiltro, filter } = useFiltro();

  return (
    <ContainerValues key={filter.index} id="container-filter-values">
      <HeaderFiltro />
      <SectionSettigs
        style={{
          display: isOpenFiltro ? 'block' : 'none',
          zIndex: 10 + zindex,
        }}
      >
        {filter.cod_tipo_dados === 1 && <TypeDate />}
        {filter.cod_tipo_dados === 2 && <TypeDate />}
        {filter.cod_tipo_dados === 3 && <TypeInteger />}
        {filter.cod_tipo_dados === 4 && <TypeDecimal />}
        {filter.cod_tipo_dados === 5 && <TypeString />}
        {filter.cod_tipo_dados === 6 && <TypeBoolean />}
      </SectionSettigs>
    </ContainerValues>
  );
};
