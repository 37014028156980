/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef } from 'react';
import Button from '@atlaskit/button';
import { AiFillCloseCircle, AiFillLock } from 'react-icons/ai';

import { ContainerValues } from '../../styles';
import { useFiltro } from '../../FiltroContext';

const HeaderFiltro: React.FC = () => {
  const divRef = useRef<HTMLDivElement | null>(null);

  const {
    isOpenFiltro,
    handlCondition,
    value,
    filter,
    removeFilter,
    isFilterBlock,
    setOpenFiltro,
    FocusFilter,
  } = useFiltro();

  useEffect(() => {
    if (divRef.current) {
      let currentElement: HTMLElement | null = divRef.current;
      let levelsUp = 10;

      while (levelsUp > 0 && currentElement?.parentElement) {
        currentElement = currentElement.parentElement;
        levelsUp--;
      }

      if (currentElement) {
        const handleClick = (event: MouseEvent) => {
          const target = event.target as HTMLElement;

          if (target.closest('#container-filter-values')) return;
          setOpenFiltro(false);
        };

        currentElement.addEventListener('click', handleClick);

        return () => {
          currentElement?.removeEventListener('click', handleClick);
        };
      }
    }
  }, [setOpenFiltro]);

  return (
    <ContainerValues key={filter.index} ref={divRef}>
      <div style={{ display: 'flex' }}>
        <Button
          type="button"
          className="filtroText"
          shouldFitContainer
          spacing="compact"
          style={{
            maxWidth: filter.des_nome === 'Loja' ? '800px' : '350px',
          }}
          // eslint-disable-next-line no-nested-ternary
          appearance={
            isFilterBlock ? 'warning' : isOpenFiltro ? 'primary' : 'default'
          }
          onClick={() => {
            setTimeout(() => {
              setOpenFiltro(!isOpenFiltro);
            }, 100);
            FocusFilter();
          }}
        >
          {` ${filter.des_nome} `}
          <span>
            {handlCondition(filter.condicao)}
            {value}
          </span>
        </Button>
        {isFilterBlock ? (
          <Button
            type="button"
            spacing="compact"
            className="closed"
            appearance="warning"
          >
            <AiFillLock />
          </Button>
        ) : (
          <Button
            type="button"
            spacing="compact"
            className="closed"
            style={{ backgroundColor: isOpenFiltro ? '#004ab7' : '#dcdfe0' }}
            appearance={isOpenFiltro ? 'primary' : 'default'}
            onClick={() => removeFilter(filter)}
          >
            <AiFillCloseCircle />
          </Button>
        )}
      </div>
    </ContainerValues>
  );
};

export default HeaderFiltro;
