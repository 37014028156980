import styled from 'styled-components';

export const ContainerValues = styled.div`
  display: flex;
  flex-direction: column;

  .tags {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
  }
  .containerTags {
    display: flex;
    flex-direction: row;
    max-width: 100%;
  }

  .Tags > span:first-child {
    display: flex;
    flex-wrap: wrap;
  }

  .react-tags {
    display: inline-block;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    max-width: 90%;
    min-width: 20px;
    min-height: 30px;
    background-color: #0052cc;
    border-radius: 2px;
    border: 1px solid #777;
    color: #ddd;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 2px;
    padding: 5px;
    padding-top: 7px;
  }
  .react-tag-remove {
    cursor: pointer;
    display: inline-block;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 600;
    background-color: #0052cc;
    border-radius: 2px;
    color: white;
    overflow: hidden;
    white-space: nowrap;
    margin: 2px;
    padding: 5px;
    margin-left: -5px;
    border-left: 0px;
    border: 1px solid #777;
    border-left: 0px;
  }

  input {
    display: inline-block;
    width: 100%;
    border: none;
    height: 42px;
  }
`;

export const InputTags = styled.input`
  width: 100px;
  height: 100px;
  padding: 10px;
  display: inline-block;
  width: 100%;
  border: none;

  background: transparent;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
}
`;
