const removerPontosEVirgula = (value = '') => {
  return value.replace(/(-(?!\d))|[^0-9|-]/g, '') || '';
};

const adicionaZeroParaManterDecimais = (value: string, qtdDecimais: number) => {
  /**
   * Verifica se possui a quantidade desejada de caracteres
   * Como queremos 2 casas decimais para os centavos, precisamos de
   * no mínimo 3 caracteres Ex: 3 -> 003 -> 0,03
   */
  if (value.length < qtdDecimais + 1) {
    return '0'.repeat(qtdDecimais + 1 - value.length) + value;
    // const valueWithPadding = '0'.repeat(3 - value.length);
  }
  return value;
};

const formataValores = (
  value: string,
  qtdDecimais: number,
  separator: string,
) => {
  /**
   * Define a posição inicial dos centavos. Como queremos 2 casas,
   * sempre iniciaremos na posição n - 2, sendo n a quantidade de
   * caracteres
   */
  const posicaoInicialDosCentavos = value.length - qtdDecimais;
  /**
   * Remove zeros a esquerda e separa valores que ficarão a esquerda
   * e a direita da virgula
   */
  const valoresAEsquerdaSemZero = value
    .substring(0, posicaoInicialDosCentavos)
    .replace(/^0+([0-9]+)/, '$1');

  const valoresADireita = value.substring(posicaoInicialDosCentavos);
  /**
   * Formata valor de acordo com moeda vigente
   */
  const preFormated = `${valoresAEsquerdaSemZero}${separator}${valoresADireita}`;
  const formated = parseFloat(preFormated).toLocaleString('pt-br', {
    minimumFractionDigits: qtdDecimais,
  });
  return formated;
};

export const moedaFormatada = (
  value: string,
  qtdDecimais = 2,
  separator = '.',
): string => {
  /**
   * Remove pontos e virgula para iniciar a formatação
   */
  value = removerPontosEVirgula(value);
  /**
   * Verifica se possui a quantidade desejada de caracteres
   * Como queremos 2 casas decimais para os centavos, precisamos de
   * no mínimo 3 caracteres Ex: 3 -> 003 -> 0,03
   */
  value = adicionaZeroParaManterDecimais(value, qtdDecimais);
  /**
   * Define posição inicial dos centavos
   * Remove zeros a esquerda
   * Separa dizimos de inteiros
   * Formata de acordo com a moeda vigente
   */
  return formataValores(value, qtdDecimais, separator);
};
