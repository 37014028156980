import React, { useState } from 'react';
import { Checkbox } from '@atlaskit/checkbox';

import Button from '@atlaskit/button';

import { useFiltro } from '../../FiltroContext';

const TypeBoolean: React.FC = () => {
  const { isFilterBlock, handleSetNewValue, filter } = useFiltro();

  const [isChecked, setIsChecked] = useState(filter.des_valor === 'true');
  const [condition] = useState(filter.condicao);

  const onChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSetValueFiltro = () => {
    handleSetNewValue({
      index: filter.index,
      des_nome: filter.des_nome,
      nome_bd: filter.nome_bd,
      condicao: condition,
      des_valor: isChecked ? 'true' : 'false',
      cod_tipo_dados: filter.cod_tipo_dados,
      cod_tipo_info: filter.cod_tipo_info,
      cod_campo: filter.cod_campo,
      isBlock: isFilterBlock,
      expressao_validacao: filter.expressao_validacao,
      listagem_info: filter.listagem_info,
      tipo_expressao: filter.tipo_expressao,
    });
  };

  return (
    <div key={filter.index}>
      <Checkbox
        isChecked={isChecked}
        onChange={onChange}
        type="number"
        step="1"
        label="Marcado?"
      />

      <Button type="button" onClick={handleSetValueFiltro} appearance="primary">
        Atualizar
      </Button>
    </div>
  );
};

export default TypeBoolean;
