import styled from 'styled-components';

export const InpuTypeString = styled.input`
  background-color: transparent;
  border: 1px solid;
  border-color: #ccc;
  box-sizing: border-box;
  padding: 10px 20px;
  color: inherit;
  cursor: inherit;
  font-size: 14px;
  min-width: 0;
  width: 100%;
  line-height: 1.4285714285714286;
`;
