/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useContext, ChangeEvent } from 'react';
import { Radio } from '@atlaskit/radio';
import Button from '@atlaskit/button';
import { FilterContext } from '~/context/filter';
import { Field } from '@atlaskit/form';
import { InputTagSt } from '../../styles';
import { Tags } from '../../MyComponents/Tags';
import { useFiltro } from '../../FiltroContext';

const options = [
  {
    description: 'Igual à',
    cod_condicao: 0,
  },
  {
    description: 'Contém',
    cod_condicao: 4,
  },
  {
    description: 'Não Contém',
    cod_condicao: 5,
  },
  {
    description: 'Preenchido',
    cod_condicao: 7,
  },
  {
    description: 'Não Preenchido',
    cod_condicao: 8,
  },
];

const optionsExpression = [
  {
    description: 'Igual à',
    cod_condicao: 0,
  },
  {
    description: 'Diferente de',
    cod_condicao: 3,
  },
  {
    description: 'Preenchido',
    cod_condicao: 7,
  },
  {
    description: 'Não Preenchido',
    cod_condicao: 8,
  },
];

const TypeString: React.FC = () => {
  // Condições
  const preenchido = 7;
  const naoPreenchido = 8;

  const { searchOnClick, isFilterBlock, handleSetNewValue, filter } =
    useFiltro();
  const { RemoveFilterEmptyByIndex } = useContext(FilterContext);

  const [value, setValue] = useState<string>('');
  const [tags, setTags] = useState<string[]>(
    Array.isArray(filter.des_valor) ? filter.des_valor : [],
  );
  const [condition, setCondition] = useState(filter.condicao || 0);

  const [isExpression, setExpression] = useState<boolean>(false);
  const [Mask, setMask] = useState<string>('');

  const handleSetNewValueFiltro = () => {
    if (tags.length < 1 && condition !== 7 && condition !== 8) {
      if (!isFilterBlock) RemoveFilterEmptyByIndex(filter.index);
      return;
    }
    handleSetNewValue({
      index: filter.index,
      des_nome: filter.des_nome,
      nome_bd: filter.nome_bd,
      condicao: condition,
      des_valor:
        condition === naoPreenchido || condition === preenchido
          ? undefined
          : tags,
      cod_tipo_dados: filter.cod_tipo_dados,
      cod_tipo_info: filter.cod_tipo_info,
      cod_campo: filter.cod_campo,
      isBlock: isFilterBlock,
      expressao_validacao: filter.expressao_validacao,
      listagem_info: filter.listagem_info,
      tipo_expressao: filter.tipo_expressao,
    });
  };

  useEffect(() => {
    handleSetNewValueFiltro();
  }, [searchOnClick]);

  useEffect(() => {
    if (filter.tipo_expressao === 1) {
      setExpression(true);

      if (filter.listagem_info) {
        const maskExp = JSON.parse(filter.listagem_info);
        setMask(maskExp[0].valor);
      }
    }
  }, [
    filter.tipo_expressao,
    filter.listagem_info,
    value,
    filter.expressao_validacao,
    filter.condicao,
  ]);

  function onChangeInput(event: ChangeEvent<HTMLInputElement>) {
    const valueInput = event.target.value;
    const ValidarCarecteresEspeciais = /\W|_/;

    let valor = '';
    for (let i = 0; i < valueInput.length; i++) {
      if (ValidarCarecteresEspeciais.test(Mask[i])) {
        valor += Mask[i];
        valor += valueInput[i].replace(/[^0-9]/g, '') || '';
      } else {
        valor += valueInput[i].replace(/[^0-9]/g, '') || '';
      }
    }
    setValue(valor);
  }

  const handleTags = (newTags: any) => {
    setTags(newTags);
  };

  return (
    <div>
      {isExpression &&
        optionsExpression.map((opt, i) => (
          <div key={`${filter.index} ${i}`}>
            <Radio
              label={opt.description}
              name={`${filter.index} ${opt.description}`}
              testId="radio-default"
              value={opt.cod_condicao.toString()}
              isChecked={condition === opt.cod_condicao}
              onChange={(e) => {
                document.getElementById('inputTag')?.focus();
                setCondition(Number(e.target.value));
              }}
            />
          </div>
        ))}

      {!isExpression &&
        options.map((opt, i) => (
          <div key={`${filter.index} ${i}`}>
            <Radio
              label={opt.description}
              name={`${filter.index} ${opt.description}`}
              testId="radio-default"
              value={opt.cod_condicao.toString()}
              isChecked={Number(condition) === Number(opt.cod_condicao)}
              onChange={(e) => {
                document.getElementById('inputTag')?.focus();
                setCondition(Number(e.target.value));
              }}
            />
          </div>
        ))}
      <div
        style={{
          display:
            condition === naoPreenchido || condition === preenchido
              ? 'none'
              : 'block',
        }}
      >
        {isExpression ? (
          <>
            <div>
              <Field
                label="Pressione Enter para Confirmar"
                name="Pressione Enter para Confirmar"
              >
                {() => (
                  <InputTagSt>
                    <Tags
                      tags={tags}
                      onChange={(e) => handleTags(e)}
                      inputProps={{
                        maxLength: Mask.length,
                        type: 'text',
                        onChangeInput,
                        value,
                      }}
                    />
                  </InputTagSt>
                )}
              </Field>
            </div>
          </>
        ) : (
          <div
            onClick={() => {
              document.getElementById('inputTag')?.focus();
            }}
          >
            <Field
              label="Pressione Enter para Confirmar"
              name="Pressione Enter para Confirmar"
            >
              {() => (
                <InputTagSt>
                  <Tags
                    tags={tags}
                    onChange={(e) => handleTags(e)}
                    inputProps={{
                      type: 'text',
                    }}
                  />
                </InputTagSt>
              )}
            </Field>
          </div>
        )}
      </div>

      <Button
        type="button"
        onClick={handleSetNewValueFiltro}
        appearance={
          tags.length < 1 &&
          condition !== preenchido &&
          condition !== naoPreenchido
            ? 'default'
            : 'primary'
        }
        isDisabled={
          tags.length < 1 &&
          condition !== preenchido &&
          condition !== naoPreenchido
        }
      >
        Atualizar
      </Button>
    </div>
  );
};

export default TypeString;
