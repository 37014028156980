import { FildsetContainer, Legend } from './style';

/* eslint-disable react/react-in-jsx-scope */
interface FildsetProps {
  label: string;
  children: JSX.Element;
}

export function Fildset({ label, children }: FildsetProps): JSX.Element {
  return (
    <FildsetContainer>
      <Legend>{label}</Legend>
      {children}
    </FildsetContainer>
  );
}
