import React from 'react';
import { ClipLoader } from 'react-spinners';
import { Container } from './styles';

/**
 * @returns Componente Componente de loading
 */
const DefaultLoader: React.FC = () => {
  return (
    <Container>
      <div className="loader">
        <ClipLoader color="#57069e" />
      </div>
    </Container>
  );
};

export default DefaultLoader;
