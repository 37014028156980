/* eslint-disable no-restricted-globals */
import React from 'react';
import { Filter } from '../Search/types';

import { FiltroContextProvider } from './FiltroContext';
import { FiltroContent } from './FiltroContent';

interface ValuesProps {
  zindex: number;
  removeFilter(item: Filter): void;
  handleSetValue(item: Filter): void;
  filter: Filter;
  isPopUp?: boolean;
  isUpdateCard?: boolean;
  searchOnClick: boolean;
  isFilterBlock?: boolean;
}

const Values: React.FC<ValuesProps> = ({
  zindex,
  handleSetValue,
  filter,
  removeFilter,
  searchOnClick,
  isPopUp,
  isFilterBlock = false,
}) => {
  return (
    <div>
      <FiltroContextProvider
        zindex={zindex}
        handleSetValue={handleSetValue}
        filter={filter}
        removeFilter={removeFilter}
        searchOnClick={searchOnClick}
        isPopUp={isPopUp}
        isFilterBlock={isFilterBlock}
      >
        <FiltroContent />
      </FiltroContextProvider>
    </div>
  );
};

export default Values;
