/* eslint-disable prettier/prettier */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  MdOutlineArrowForward,
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
  MdOutlineArrowBack,
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdArrowUpward,
  MdArrowDownward,
} from 'react-icons/md';
import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';
import {
  Container,
  ContainerItem,
  Controller,
  List,
  Button,
  ListaItem,
} from './styles';

interface OptionsProps {
  order: number;
  cod_campo: number;
  des_campo: string;
}

interface DualListBoxProps {
  optionAvailable: OptionsProps[];
  setOptionAvailable: (item: OptionsProps[]) => void;
  optionSelected: OptionsProps[];
  setOptionSelected: (item: OptionsProps[]) => void;
}
interface SeparateOptionsProps {
  allOptions: OptionsProps[];
  cod_campo: number | undefined;
}

interface SortOptionsProps {
  allOptions: OptionsProps[];
  setOptions: (item: OptionsProps[]) => void;
}
interface UniqueOptionsProps {
  allOptions: OptionsProps[];
}

export const DualListBox: React.FC<DualListBoxProps> = ({
  optionAvailable,
  setOptionAvailable,
  optionSelected,
  setOptionSelected,
}) => {
  const [selected, setSeleted] = useState<number>();

  useEffect(() => {
    document.getElementById(`${selected}`)?.focus();
  }, [optionAvailable, optionSelected]);

  function handleCampo(cod_campo: number): void {
    setSeleted(cod_campo);
  }

  function moveAllAvailableToSelected() {
    setOptionSelected([...optionSelected, ...optionAvailable]);
    setOptionAvailable([]);
  }

  function moveItemAvailableToSelected() {
    const { option, options } = separateOptions({
      allOptions: optionAvailable,
      cod_campo: selected,
    });
    const optionsDestination = optionSelected;
    if (option) {
      optionsDestination.splice(optionsDestination.length, 0, option);
      sortOptions({
        allOptions: optionsDestination,
        setOptions: setOptionSelected,
      });
      sortOptions({
        allOptions: options,
        setOptions: setOptionAvailable,
      });
    }
  }

  function moveItemSelectedToAvailable() {
    const { option, options } = separateOptions({
      allOptions: optionSelected,
      cod_campo: selected,
    });
    const optionsDestination = optionAvailable;
    if (option) {
      optionsDestination.splice(optionsDestination.length, 0, option);
      sortOptions({
        allOptions: optionsDestination,
        setOptions: setOptionAvailable,
      });
      sortOptions({
        allOptions: options,
        setOptions: setOptionSelected,
      });
    }
  }

  function moveAllSelectedToAvailable() {
    setOptionAvailable([...optionSelected, ...optionAvailable]);
    setOptionSelected([]);
  }
  function moveItemSelectedToMaxUpOrMaxDown(action: string) {
    const { option, options } = separateOptions({
      allOptions: optionSelected,
      cod_campo: selected,
    });
    if (option) {
      if (action === 'up') {
        options.unshift(option);
      } else {
        options.splice(options.length, 0, option);
      }
      sortOptions({
        allOptions: options,
        setOptions: setOptionSelected,
      });
    }
  }

  function moveItemSelectedToUpOrDown(action: string) {
    const { option, options } = separateOptions({
      allOptions: optionSelected,
      cod_campo: selected,
    });
    if (option) {
      if (action === 'up') {
        const order = option.order - 2;
        options.splice(order < 0 ? 0 : order, 0, option);
      } else {
        options.splice(option.order, 0, option);
      }
      sortOptions({
        allOptions: options,
        setOptions: setOptionSelected,
      });
    }
  }

  function separateOptions({ allOptions, cod_campo }: SeparateOptionsProps) {
    const options = allOptions.filter((item) => item.cod_campo !== cod_campo);
    const option = allOptions.find((item) => item.cod_campo === cod_campo);

    return {
      options,
      option,
    };
  }
  function sortOptions({ allOptions, setOptions }: SortOptionsProps) {
    const dataOrdenado = allOptions.map((item, index) => ({
      order: index + 1,
      cod_campo: item.cod_campo,
      des_campo: item.des_campo,
    }));
    const dataUnique = uniqueOptions({ allOptions: dataOrdenado });
    setOptions(dataUnique);
  }
  function uniqueOptions({ allOptions }: UniqueOptionsProps) {
    const dataUnique = [];
    const map = new Map();
    // eslint-disable-next-line no-restricted-syntax
    for (const option of allOptions) {
      if (!map.has(option.cod_campo)) {
        map.set(option.cod_campo, true); // set any value to Map
        dataUnique.push({
          order: option.order,
          cod_campo: option.cod_campo,
          des_campo: option.des_campo,
        });
      }
    }
    return dataUnique;
  }

  function handleOnDragEnd(result: any): void {
    const { destination, source } = result;
    if (result.destination) {
      // Se movimentar dentro da mesma Lista
      if (destination.droppableId === source.droppableId) {
        const { option, options } = separateOptions({
          allOptions:
            destination.droppableId === 'selecionados'
              ? optionSelected
              : optionAvailable,
          cod_campo: selected,
        });
        if (option) {
          options.splice(destination.index, 0, option);
        }
        sortOptions({
          allOptions: options,
          setOptions:
            destination.droppableId === 'selecionados'
              ? setOptionSelected
              : setOptionAvailable,
        });
      } else {
        const { option, options } = separateOptions({
          allOptions:
            source.droppableId === 'selecionados'
              ? optionSelected
              : optionAvailable,
          cod_campo: selected,
        });
        sortOptions({
          allOptions: options,
          setOptions:
            source.droppableId === 'selecionados'
              ? setOptionSelected
              : setOptionAvailable,
        });
        if (option) {
          const optionsDestination =
            destination.droppableId === 'selecionados'
              ? optionSelected
              : optionAvailable;

          optionsDestination.splice(destination.index, 0, option);
          sortOptions({
            allOptions: optionsDestination,
            setOptions:
              destination.droppableId === 'selecionados'
                ? setOptionSelected
                : setOptionAvailable,
          });
        }
      }
    }
  }

  const sortArrayByDesCampo = useCallback(
    (arr: OptionsProps[]): OptionsProps[] => {
      const normalizeString = (str: string): string => {
        return str
          .trim()
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/[/?]/g, '')
          .replace(/[^a-z0-9]/g, '');
      };

      return arr.sort((a, b) => {
        const campoA = normalizeString(a.des_campo);
        const campoB = normalizeString(b.des_campo);

        return campoA.localeCompare(campoB, undefined, { sensitivity: 'base' });
      });
    },
    [],
  );

  return (
    <Container>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <ContainerItem>
          <span className="title">Campos Disponíveis</span>
          <List>
            <Droppable droppableId="disponiveis" key="1">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    background: snapshot.isDraggingOver ? '#dfe1e6' : '',
                    height: '100%',
                  }}
                >
                  {sortArrayByDesCampo(optionAvailable).map((item, index) => (
                    <Draggable
                      draggableId={`${item.cod_campo}`}
                      key={item.cod_campo}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          id={`${item.cod_campo}`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ListaItem
                            onPointerDownCapture={() => {
                              handleCampo(item.cod_campo);
                            }}
                            className={
                              selected === item.cod_campo
                                ? 'selected'
                                : 'unselected'
                            }
                          >
                            {item.des_campo}
                          </ListaItem>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </List>
          <Controller>
            <Button type="button" onClick={() => moveAllAvailableToSelected()}>
              <HiChevronDoubleRight />
            </Button>
            <Button type="button" onClick={() => moveItemAvailableToSelected()}>
              <MdKeyboardArrowRight />
            </Button>
            <Button type="button" onClick={() => moveItemSelectedToAvailable()}>
              <MdKeyboardArrowLeft />
            </Button>
            <Button type="button" onClick={() => moveAllSelectedToAvailable()}>
              <HiChevronDoubleLeft />
            </Button>
          </Controller>
        </ContainerItem>
        <ContainerItem>
          <span className="title">Campos Selecionados</span>
          <List>
            <Droppable droppableId="selecionados" key="2">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    background: snapshot.isDraggingOver ? '#dfe1e6' : '',
                    height: '100%',
                  }}
                >
                  {optionSelected.map((item, index) => (
                    <Draggable
                      draggableId={`${item.cod_campo}`}
                      key={item.cod_campo}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          id={`${item.cod_campo}`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ListaItem
                            onPointerDownCapture={() => {
                              handleCampo(item.cod_campo);
                            }}
                            className={
                              selected === item.cod_campo
                                ? 'selected'
                                : 'unselected'
                            }
                          >
                            {item.des_campo}
                          </ListaItem>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </List>
          <Controller>
            <Button
              type="button"
              onClick={() => moveItemSelectedToMaxUpOrMaxDown('up')}
            >
              <MdArrowUpward />
            </Button>
            <Button
              type="button"
              onClick={() => moveItemSelectedToUpOrDown('up')}
            >
              <MdKeyboardArrowUp />
            </Button>
            <Button
              type="button"
              onClick={() => moveItemSelectedToUpOrDown('down')}
            >
              <MdKeyboardArrowDown />
            </Button>
            <Button
              type="button"
              onClick={() => moveItemSelectedToMaxUpOrMaxDown('down')}
            >
              <MdArrowDownward />
            </Button>
          </Controller>
        </ContainerItem>
      </DragDropContext>
    </Container>
  );
};
